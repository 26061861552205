<template>
	<el-aside :width="$store.state.isCollapsed?'64px':'240px'">
		<el-menu 
		:router="true" 
		:default-active="route.fullPath" 
		:collapse="$store.state.isCollapsed"
		:collapse-transition="false">
			<el-menu-item index="/index">
			    <el-icon><home-filled /></el-icon>
			    <span>首页</span>
			</el-menu-item>
			<el-sub-menu index="/zhgl" v-admin>
				<template #title>
					<el-icon><Reading /></el-icon>
					<span>账号管理</span>
				</template>
				<el-menu-item index="/zhgl/zhgladd">添加账号</el-menu-item>
				<el-menu-item index="/zhgl/zhgllist">账号列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/gssy">
				<template #title>
					<el-icon><Notification /></el-icon>
					<span>公司首页</span>
				</template>
				<el-menu-item index="/gssy/gssyadd">添加首页数据</el-menu-item>
				<el-menu-item index="/gssy/gssylist">首页数据列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/gsjj">
				<template #title>
					<el-icon><EditPen /></el-icon>
					<span>公司简介</span>
				</template>
				<el-menu-item index="/gsjj/gsjjadd">添加简介数据</el-menu-item>
				<el-menu-item index="/gsjj/gsjjlist">简介列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/news">
				<template #title>
					<el-icon><MessageBox /></el-icon>
					<span>新闻中心</span>
				</template>
				<el-menu-item index="/news/newsadd">添加新闻数据</el-menu-item>
				<el-menu-item index="/news/newslist">新闻列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/product">
				<template #title>
					<el-icon><CameraFilled /></el-icon>
					<span>产品展示</span>
				</template>
				<el-menu-item index="/product/productadd">添加产品数据</el-menu-item>
				<el-menu-item index="/product/productlist">产品列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/byzs">
				<template #title>
					<el-icon><PictureFilled /></el-icon>
					<span>布样展示</span>
				</template>
				<el-menu-item index="/byzs/byzsscadd">添加丝绸数据</el-menu-item>
				<el-menu-item index="/byzs/byzssclist">丝绸列表</el-menu-item>
				<el-menu-item index="/byzs/byzscxadd">添加刺绣数据</el-menu-item>
				<el-menu-item index="/byzs/byzscxlist">刺绣列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/trszcd">
				<template #title>
					<el-icon><SuitcaseLine /></el-icon>
					<span>床垫展示</span>
				</template>
				<el-menu-item index="/trszcd/trszcdadd">添加床垫数据</el-menu-item>
				<el-menu-item index="/trszcd/trszcdlist">床垫列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/hmjjwx">
				<template #title>
					<el-icon><Tools /></el-icon>
					<span>家具维修</span>
				</template>
				<el-menu-item index="/hmjjwx/hmjjwxadd">添加数据</el-menu-item>
				<el-menu-item index="/hmjjwx/hmjjwxlist">数据列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/zdlf">
				<template #title>
					<el-icon><Tickets /></el-icon>
					<span>坐垫量法</span>
				</template>
				<el-menu-item index="/zdlf/zdlfadd">添加数据</el-menu-item>
				<el-menu-item index="/zdlf/zdlflist">数据列表</el-menu-item>
			</el-sub-menu>
			<el-sub-menu index="/lxwm">
				<template #title>
					<el-icon><Phone /></el-icon>
					<span>联系我们</span>
				</template>
				<el-menu-item index="/lxwm/lxwmadd">添加数据</el-menu-item>
				<el-menu-item index="/lxwm/lxwmlist">数据列表</el-menu-item>
			</el-sub-menu>
			 <el-sub-menu index="/gggl">
			 	<template #title>
			 		<el-icon><More /></el-icon>
			 		<span>其他管理</span>
			 	</template>
			 	<el-menu-item index="/qtgl/gggl">广告管理</el-menu-item>
			 	<el-menu-item index="/qtgl/wzsz">网站设置</el-menu-item>
			 </el-sub-menu>
		</el-menu>
	</el-aside>
</template>

<script setup>
	import {useRoute} from 'vue-router'
	import {useStore } from 'vuex'
	import {HomeFilled,Avatar,Notification,EditPen,MessageBox,CameraFilled,PictureFilled,SuitcaseLine,Tools,Tickets,Phone,More,Reading} from '@element-plus/icons-vue'
	const store = useStore()
	const route = useRoute()
	const vAdmin = {
	    mounted(el){
	        if(store.state.userInfo.role!==1){
	            el.parentNode.removeChild(el)
	        }
	    }
	}
</script>

<style lang="scss" scoped>
	.el-aside {
		height: 100vh;

		.el-menu {
			height: 100vh;
		}
	}
</style>