<template>
    <div>
        <el-card>
            <el-page-header
                content="丝绸列表"
                icon=""
                title="布样展示"
            />
			<el-scrollbar style="height:500px;">
            <el-table
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="byzsscname"
                    label="名称"
                    width="180"
                />
                <el-table-column
                    label="图片"
                    width="180"
                >
                    <template #default="scope">
                        <div v-if="scope.row.avatar">
                            <el-avatar
							    shape="square"
                                :size="50" style="width: 65px; height: 50px;"
                                :src="proxy.$key+scope.row.avatar"
                            ></el-avatar>
                        </div>
                        <div v-else>
                            <el-avatar
                                :size="50" style="width: 65px; height: 50px;"
                                src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
                            />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-button
                            size="small"
                            @click="handleEdit(scope.row)"
                        >编辑</el-button>
                        <el-popconfirm
                            title="你确定要删除吗?"
                            confirmButtonText="确定"
                            cancelButtonText="取消"
                            @confirm="handleDelete(scope.row)"
                        >
                            <template #reference>
                                <el-button
                                    size="small"
                                    type="danger"
                                >删除</el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
			</el-scrollbar>
        </el-card>
<!-- 弹出框 -->
        <el-dialog
            v-model="dialogVisible"
            title="编辑新闻"
            width="50%"
        >
            <el-form
                ref="byzsscFormRef"
                :model="byzsscForm"
                :rules="byzsscFormRules"
                label-width="80px"
                class="demo-ruleForm"
            >
                <el-form-item
                    label="名称"
                    prop="byzsscname"
                >
                    <el-input v-model="byzsscForm.byzsscname" />
                </el-form-item>
				<el-form-item label="头像" prop="avatar">
				<Upload :avatar="byzsscForm.avatar" @kerwinchange="handleChange"/>
				</el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button
                        type="primary"
                        @click="handleEditConfirm()"
                    >确认</el-button>
                </span>
            </template>
        </el-dialog>
		<div class="pagin">
			<el-pagination background layout="prev, pager, next,total" 
			:total="data.total"
			:page-size="data.perPage"
			:current-page="data.page"
			@update:current-page="data.page = $event"
			@current-change="getTableData"
			/>
		</div>
    </div>
</template>
<script setup>
import { ref, onMounted,reactive } from "vue";
import axios from "axios";
import upload from "@/util/upload";
import Upload from '@/components/upload/Upload'
import {getCurrentInstance} from 'vue';
const { proxy } = getCurrentInstance();
const data = reactive({
		total:0,//总条数
		page:1,//第几页
		perPage:12,//每页显示条数
	})
const dialogVisible = ref(false);
const byzsscFormRef = ref();
let byzsscForm = reactive({
  byzsscname: "",
  avatar: "",
  file: null
});
const byzsscFormRules = reactive({
  byzsscname: [{ required: true, message: "请输入名字", trigger: "blur" }],
  avatar: [{ required: true, message: "请输图片", trigger: "blur" }],
});
const tableData = ref([]);
onMounted(() => {
  getTableData();
});
// 获取全部数据
const getTableData = async () => {
  const res = await axios.get("/adminapi/byzssc/list",{
	  params:data
  });
  // 获取总页数
  data.total = res.data.total
  // console.log(res.data)
  tableData.value = res.data.data;
};
// 编辑回调
const handleEdit = async (item) => {
  const res = await axios.get(`/adminapi/byzssc/list/${item._id}`)
  Object.assign(byzsscForm,res.data.data[0])
  dialogVisible.value = true;
};
//每次选择完图片之后的回调
	const handleChange = file => {
		 byzsscForm.avatar = URL.createObjectURL(file);
		 byzsscForm.file = file;
	};
//编辑确认回调
const handleEditConfirm = ()=>{
    byzsscFormRef.value.validate(async (valid)=>{
        if(valid){
			// console.log(byzsscForm);
            // 1- 更新后端
            await upload("/adminapi/byzssc/list",byzsscForm)
             //2- dialog隐藏
            dialogVisible.value = false
             //3-获取table数据
            getTableData()
        }
    })
}
// 删除选中数据
const handleDelete = async (item)=>{
    // console.log(item)
	const idavatar = reactive({
		_id: item._id, //id
		avatar: item.avatar, //图片名称
	})
    await axios.delete("/adminapi/byzssc/list",{
		params:idavatar
	})
    await getTableData()
}
</script>
<style lang="scss" scoped>
.el-table {
  margin-top: 50px;
}
::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		transition: var(--el-transition-duration-fast);
	}

	::v-deep .avatar-uploader .el-upload:hover {
		border-color: var(--el-color-primary);
	}

	::v-deep .el-icon.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
	}
</style>
