<template>
    <div>
        <el-page-header
            content="产品数据"
            icon=""
            title="产品展示"
        />
        <el-form
            ref="productFormRef"
            :model="productForm"
            :rules="productFormRules"
            label-width="80px"
            class="demo-ruleForm"
        >
            <el-form-item
                label="产品名称"
                prop="productname"
            >
                <el-input v-model="productForm.productname" />
            </el-form-item>
			<el-form-item
			    label="布样编号"
			    prop="productnumber"
			>
			    <el-input v-model="productForm.productnumber" />
			</el-form-item>
            <el-form-item
                label="产品图片"
                prop="avatar"
            >
                <Upload
                    :avatar="productForm.avatar"
                    @kerwinchange="handleChange"
                />
            </el-form-item>

            <el-form-item>
                <el-button
                    type="primary"
                    @click="submitForm()"
                >添加产品</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script setup>
import { ref, reactive } from "vue"
import Upload from "@/components/upload/Upload"
import upload from '@/util/upload'
import {useRouter} from 'vue-router'
import axios from 'axios'
const dialogVisible = ref(false)
const productFormRef = ref()
const productForm = reactive({
  productname: "",
  productnumber: "",
  avatar: "",
  file: null,
});

const productFormRules = reactive({
  productname: [{ required: true, message: "请输入产品名称", trigger: "blur" }],
   // productnumber: [{ required: true, message: "请输入产品编号", trigger: "blur" }],
  avatar: [{ required: true, message: "请上传产品图片", trigger: "blur" }]
});

//每次选择完图片之后的回调
const handleChange = file => {
  productForm.avatar = URL.createObjectURL(file);
  productForm.file = file;
};
const router = useRouter()
const submitForm = ()=>{
    productFormRef.value.validate(async (valid)=>{
        if(valid){
            //提交数据到后端
			console.log(productForm);
            await upload("/adminapi/product/add",productForm)
            router.push(`/product/productlist`)
        }
    })
}
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  margin-top: 50px;
}
</style>
