<template>
	<el-page-header
	    content="添加数据"
	    icon=""
	    title="公司简介"
	/>
	<el-form
	    ref="gsjjFormRef"
	    :model="gsjjForm"
	    :rules="gsjjFormRules"
	    label-width="80px"
	    class="demo-ruleForm"
	>
	<el-form-item
	    label="标题"
	    prop="gsjjname"
	>
	    <el-input v-model="gsjjForm.gsjjname" />
	</el-form-item>
	    <el-form-item
	        label="内容"
	        prop="content"
	    >
		<editor @event="handleChange"/>
	    </el-form-item>
		<el-form-item>
		    <el-button
		        type="primary"
		        @click="submitForm()" 
		    >添加数据</el-button>
		</el-form-item>
	</el-form>
</template>

<script setup>
	import { ref, reactive } from "vue"
	import editor from '@/components/editor/Editor'
	import {useRouter} from 'vue-router'
	import axios from 'axios'
	const router = useRouter()
	const gsjjFormRef = ref();
	const gsjjForm = reactive({
	  content: "",
	  gsjjname:''
	  // file: null,
	});
	const gsjjFormRules = reactive({
	 content: [{ required: true, message: "请输入内容", trigger: "blur" }],
	 gsjjname: [{ required: true, message: "请输入标题", trigger: "blur" }],
	});
	// 文本数据传输
	const handleChange = data => {
	  gsjjForm.content = data;
	};
	const submitForm = ()=>{
	    gsjjFormRef.value.validate(async (valid)=>{
	        if(valid){
	            //后台通信,
	           await axios.post("/adminapi/gsjj/add",gsjjForm)
	            router.push(`/gsjj/gsjjlist`)
	        }
	    })
	}
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  margin-top: 50px;
}
</style>