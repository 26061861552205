<template>
    <div>
        <el-page-header
            content="添加床垫"
            icon=""
            title="天然山棕床垫"
        />

        <el-form
            ref="trszcdFormRef"
            :model="trszcdForm"
            :rules="trszcdFormRules"
            label-width="80px"
            class="demo-ruleForm"
        >
            <el-form-item
                label="床垫名称"
                prop="trszcdname"
            >
                <el-input v-model="trszcdForm.trszcdname" />
            </el-form-item>
            <el-form-item
                label="床垫图片"
                prop="avatar"
            >
                <Upload
                    :avatar="trszcdForm.avatar"
                    @kerwinchange="handleChange"
                />
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    @click="submitForm()"
                >添加床垫</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script setup>
import { ref, reactive } from "vue"
import Upload from "@/components/upload/Upload"
import upload from '@/util/upload'
import {useRouter} from 'vue-router'
import axios from 'axios'
const trszcdFormRef = ref()
const trszcdForm = reactive({
  trszcdname: "",
  avatar: "",
  file: null,
});

const trszcdFormRules = reactive({
  trszcdname: [{ required: true, message: "请输入床垫名称", trigger: "blur" }],
  avatar: [{ required: true, message: "请上传床垫图片", trigger: "blur" }]
});

//每次选择完图片之后的回调
const handleChange = file => {
  trszcdForm.avatar = URL.createObjectURL(file);
  trszcdForm.file = file;
};
const router = useRouter()
// 提交按钮
const submitForm = ()=>{
    trszcdFormRef.value.validate(async (valid)=>{
        if(valid){
            //提交数据到后端
			// console.log(trszcdForm);
            await upload("/adminapi/trszcd/add",trszcdForm)
            router.push(`/trszcd/trszcdlist`)
        }
    })
}
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  margin-top: 50px;
}
</style>
