<template>
    <div>
        <el-page-header
            content="添加数据"
            icon=""
            title="首页数据"
        />
<!-- 滚动条 -->
			<el-scrollbar style="height:600px;">
        <el-form
            ref="gssyFormRef"
            :model="gssyForm"
            :rules="gssyFormRules"
            label-width="80px"
            class="demo-ruleForm"
        >
		<el-form-item
		    label="首页图片"
		    prop="avatar"
		>
		    <Upload
		        :avatar="gssyForm.avatar"
		        @kerwinchange="handleChange"
		    />
		</el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    @click="submitForm()"
                >添加数据</el-button>
            </el-form-item>
        </el-form>
		
		<!-- 友情链接 -->
		<el-form
		    ref="yqFormRef"
		    :model="yqForm"
		    :rules="yqFormRules"
		    label-width="80px"
		    class="demo-ruleForm"
		>
		<el-form-item
		    label="链接名称"
		    prop="yqname"
		>
		    <el-input v-model="yqForm.yqname" />
		</el-form-item>
		<el-form-item
		    label="链接地址"
		    prop="yqlink"
		>
		    <el-input v-model="yqForm.yqlink" />
		</el-form-item>
		    <el-form-item>
		        <el-button
		            type="primary"
		            @click="submitForm1()"
		        >添加数据</el-button>
		    </el-form-item>
		</el-form>
		
		
		<!-- 微信qq号上传座机 -->
		
		<el-form
		    ref="wxqqpFormRef"
		    :model="wxqqpForm"
		    :rules="wxqqpFormRules"
		    label-width="80px"
		    class="demo-ruleForm"
		>
		    <el-form-item
		        label="座机号"
		        prop="landline"
		    >
		        <el-input v-model="wxqqpForm.landline" />
		    </el-form-item>
			<el-form-item
			    label="QQ号"
			    prop="qqh"
			>
			    <el-input v-model="wxqqpForm.qqh" />
			</el-form-item>
			<el-form-item
			    label="微信号"
			    prop="wxh"
			>
			    <el-input v-model="wxqqpForm.wxh" />
			</el-form-item>
		    <el-form-item
		        label="微信图片"
		        prop="avatar"
		    >
		        <Upload
		            :avatar="wxqqpForm.avatar"
		            @kerwinchange="handleChange3"
		        />
		    </el-form-item>
		
		    <el-form-item>
		        <el-button
		            type="primary"
		            @click="submitForm3()"
		        >添加数据</el-button>
		    </el-form-item>
		</el-form>
		</el-scrollbar>
    </div>
	
</template>

<script setup>
import { ref, reactive } from "vue"
import Upload from "@/components/upload/Upload"
import upload from '@/util/upload'
import {useRouter} from 'vue-router'
import axios from 'axios'
const gssyFormRef = ref()
const gssyForm = reactive({
  avatar: "",
  file: null,
});
// 友情链接
const yqFormRef = ref()
const yqForm = reactive({
 yqname:"",
 yqlink:""
});

const gssyFormRules = reactive({
  avatar: [{ required: true, message: "请上传图片", trigger: "blur" }],
 
});
const yqFormRules = reactive({
  yqname: [{ required: true, message: "请填写", trigger: "blur" }],
  yqlink: [{ required: true, message: "请填写", trigger: "blur" }],
});

//每次选择完图片之后的回调
const handleChange = file => {
  gssyForm.avatar = URL.createObjectURL(file);
  gssyForm.file = file;
};

const submitForm = ()=>{
    gssyFormRef.value.validate(async (valid)=>{
        if(valid){
			// console.log(gssyForm);
            //提交数据到后端
            await upload("/adminapi/gssy/add",gssyForm)
            router.push(`/gssy/gssylist`)
        }
    })
}
const submitForm1 = ()=>{
    yqFormRef.value.validate(async(valid)=>{
        if(valid){
			// console.log(yqForm);
            //提交数据到后端
          const res =  await axios.post("/adminapi/yq/add",yqForm)
            router.push(`/gssy/gssylist`)
        }
    })
}


// 微信QQ
const wxqqpFormRef = ref()
const wxqqpForm = reactive({
  landline: "",
  qqh: "",
  wxh: "",
  avatar: "",
  file: null,
});

const wxqqpFormRules = reactive({
  landline: [{ required: true, message: "请输入座机号", trigger: "blur" }],
  qqh: [{ required: true, message: "请输入QQ号", trigger: "blur" }],
  wxh: [{ required: true, message: "请输入微信号", trigger: "blur" }],
  avatar: [{ required: true, message: "请上传微信图片", trigger: "blur" }]
});

//每次选择完图片之后的回调
const handleChange3 = file => {
  wxqqpForm.avatar = URL.createObjectURL(file);
  wxqqpForm.file = file;
};
const router = useRouter()
const submitForm3 = ()=>{
    wxqqpFormRef.value.validate(async (valid)=>{
        if(valid){
            //提交数据到后端
			console.log(wxqqpForm);
            await upload("/adminapi/wxqq/add",wxqqpForm)
        }
    })
}
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  margin-top: 50px;
}
</style>
