<template>
	<div>
		<el-page-header content="数据列表" icon="" title="数据管理" />
		<el-form
		    ref="gsjjFormRef"
		    :model="gsjjForm"
			:rules="gsjjFormRules"
		    label-width="80px"
		    class="demo-ruleForm"
		>
		    <el-form-item
		        label="标题"
		        prop="gsjjname"
		    >
		        <el-input v-model="gsjjForm.gsjjname" />
		    </el-form-item>
			<el-form-item
			    label="内容"
			    prop="content"
			>
			<editor @event="handleChange" :content="gsjjForm.content" v-if="showEditor"/>
			</el-form-item>
			<el-form-item>
			    <el-button
			        type="primary"
			        @click="submitForm()" 
			    >更新数据</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script setup>
	import { ref, onMounted,reactive } from "vue";
	import axios from "axios";
	import editor from '@/components/editor/Editor'
	const showEditor = ref(false)
	const gsjjFormRef = ref();
	let gsjjForm = reactive({
	  gsjjname: "",
	  content: "",
	});
	const gsjjFormRules = reactive({
	 content: [{ required: true, message: "请输入内容", trigger: "blur" }],
	 gsjjname: [{ required: true, message: "请输入标题", trigger: "blur" }],
	});
	// 文本数据传输
	const handleChange = data => {
	  gsjjForm.content = data;
	};
	onMounted(()=>{
		getTableData()
	})
	const getTableData = async()=>{
		const res = await axios.get(`/adminapi/Gsjj/list`)
		console.log(res.data);
		Object.assign(gsjjForm,res.data.data[0]);
		showEditor.value = true; 
	}
	const submitForm = async() =>{
		const res = await axios.post(`/adminapi/Gsjj/list`,gsjjForm)
		getTableData()
	}
</script>

<style>
	.demo-ruleForm {
	  margin-top: 50px;
	}
</style>