<template>
    <div>
        <el-page-header
            content="添加数据"
            icon=""
            title="红木家具维修"
        />

        <el-form
            ref="hmjjwxFormRef"
            :model="hmjjwxForm"
            :rules="hmjjwxFormRules"
            label-width="80px"
            class="demo-ruleForm"
        >
            <el-form-item
                label="名称"
                prop="hmjjwxname"
            >
                <el-input v-model="hmjjwxForm.hmjjwxname" />
            </el-form-item>
            <el-form-item
                label="简介"
                prop="hmjjwxtxt"
            >
                <el-input
                    v-model="hmjjwxForm.hmjjwxtxt"
                    type="textarea"
                />
            </el-form-item>
			<el-form-item
			    label="内容"
			    prop="content"
			>
			<editor @event="handleChange2"/>
			</el-form-item>
            <el-form-item
                label="图片"
                prop="avatar"
            >
                <Upload
                    :avatar="hmjjwxForm.avatar"
                    @kerwinchange="handleChange"
                />
            </el-form-item>

            <el-form-item>
                <el-button
                    type="primary"
                    @click="submitForm()"
                >添加数据</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script setup>
import { ref, reactive } from "vue"
import editor from '@/components/editor/Editor'
import Upload from "@/components/upload/Upload"
import upload from '@/util/upload'
import {useRouter} from 'vue-router'
import axios from 'axios'
const hmjjwxFormRef = ref()
const hmjjwxForm = reactive({
  hmjjwxname: "",
  content:"",
  hmjjwxtxt: "",
  avatar: "",
  file: null,
});

const hmjjwxFormRules = reactive({
  hmjjwxname: [{ required: true, message: "请输入名称", trigger: "blur" }],
  content: [{ required: true, message: "请输入内容", trigger: "blur" }],
  hmjjwxtxt: [{ required: true, message: "请输入简介", trigger: "blur" }],
  avatar: [{ required: true, message: "请上传图片", trigger: "blur" }]
});
// 文本数据传输
	const handleChange2 = data => {
	  hmjjwxForm.content = data;
	};
//每次选择完图片之后的回调
const handleChange = file => {
  hmjjwxForm.avatar = URL.createObjectURL(file);
  hmjjwxForm.file = file;
};
const router = useRouter()
const submitForm = ()=>{
    hmjjwxFormRef.value.validate(async (valid)=>{
        if(valid){
            //提交数据到后端
            await upload("/adminapi/hmjjwx/add",hmjjwxForm)
            router.push(`/hmjjwx/hmjjwxlist`)
        }
    })
}
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  margin-top: 50px;
}
</style>
