<template>
    <div>
        <el-page-header
            content="添加丝绸"
            icon=""
            title="布样展示"
        />

        <el-form
            ref="byzsscFormRef"
            :model="byzsscForm"
            :rules="byzsscFormRules"
            label-width="80px"
            class="demo-ruleForm"
        >
            <el-form-item
                label="布样编号"
                prop="byzsscname"
            >
                <el-input v-model="byzsscForm.byzsscname" />
            </el-form-item>
            <el-form-item
                label="布样图片"
                prop="avatar"
            >
                <Upload
                    :avatar="byzsscForm.avatar"
                    @kerwinchange="handleChange"
                />
            </el-form-item>

            <el-form-item>
                <el-button
                    type="primary"
                    @click="submitForm()"
                >添加布样</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script setup>
import { ref, reactive } from "vue"
import Upload from "@/components/upload/Upload"
import upload from '@/util/upload'
import {useRouter} from 'vue-router'
import axios from 'axios'
const byzsscFormRef = ref()
const byzsscForm = reactive({
  byzsscname: "",
  avatar: "",
  file: null,
});

const byzsscFormRules = reactive({
  byzsscname: [{ required: true, message: "请输入产品名称", trigger: "blur" }],
  avatar: [{ required: true, message: "请上传产品图片", trigger: "blur" }]
});

//每次选择完图片之后的回调
const handleChange = file => {
  byzsscForm.avatar = URL.createObjectURL(file);
  byzsscForm.file = file;
};
const router = useRouter()
const submitForm = ()=>{
    byzsscFormRef.value.validate(async (valid)=>{
        if(valid){
            //提交数据到后端
			// console.log(byzsscForm);
            await upload("/adminapi/byzssc/add",byzsscForm)
            router.push(`/byzs/byzssclist`)
        }
    })
}
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  margin-top: 50px;
}
</style>
