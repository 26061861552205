import Home from '../views/Home/Home';
import ZhglAdd from '../views/Zhgl/ZhglAdd';
import ZhglList from '../views/Zhgl/ZhglList';
import ByzsscAdd from '../views/Byzs/ByzsscAdd';
import ByzsscList from '../views/Byzs/ByzsscList';
import ByzscxAdd from '../views/Byzs/ByzscxAdd';
import ByzscxList from '../views/Byzs/ByzscxList';
import GsjjAdd from '../views/Gsjj/GsjjAdd';
import GsjjList from '../views/Gsjj/GsjjList';
import GssyAdd from '../views/Gssy/GssyAdd';
import GssyList from '../views/Gssy/GssyList';
import HmjjwxAdd from '../views/Hmjjwx/HmjjwxAdd';
import HmjjwxList from '../views/Hmjjwx/HmjjwxList';
import LxwmAdd from '../views/Lxwm/LxwmAdd';
import LxwmList from '../views/Lxwm/LxwmList';
import NewsAdd from '../views/News/NewsAdd';
import NewsList from '../views/News/NewsList';
import ProductAdd from '../views/Product/ProductAdd';
import ProductList from '../views/Product/ProductList';
import TrszcdAdd from '../views/Trszcd/TrszcdAdd';
import TrszcdList from '../views/Trszcd/TrszcdList';
import ZdlfAdd from '../views/Zdlf/ZdlfAdd';
import ZdlfList from '../views/Zdlf/ZdlfList';
import Gggl from '../views/qtgl/gggl';
import Wzsz from '../views/qtgl/wzsz';
import NotFound from '../views/notfound/NotFound.vue'
import wzszVue from '@/views/qtgl/wzsz.vue';
const routes = [
	{
		path:"/index",
		component:Home
	},
	{
		path:"/zhgl/zhgladd",
		component:ZhglAdd,
		 requireAdmin:true
	},
	{
		path:"/qtgl/gggl",
		component:Gggl,
		 requireAdmin:true
	},
	{
		path:"/qtgl/wzsz",
		component:Wzsz,
		 requireAdmin:true
	},
	{
		path:"/zhgl/zhgllist",
		component:ZhglList,
		 requireAdmin:true
	},
	{
		path:"/byzs/byzsscadd",
		component:ByzsscAdd
	},
	{
		path:"/byzs/byzssclist",
		component:ByzsscList
	},
	{
		path:"/byzs/byzscxadd",
		component:ByzscxAdd
	},
	{
		path:"/byzs/byzscxlist",
		component:ByzscxList
	},
	{
		path:"/gsjj/gsjjadd",
		component:GsjjAdd
	},
	{
		path:"/gsjj/gsjjlist",
		component:GsjjList
	},
	{
		path:"/gssy/gssyadd",
		component:GssyAdd
	},
	{
		path:"/gssy/gssylist",
		component:GssyList
	},
	{
		path:"/lxwm/lxwmadd",
		component:LxwmAdd
	},
	{
		path:"/lxwm/lxwmlist",
		component:LxwmList
	},
	{
		path:"/news/newsadd",
		component:NewsAdd
	},
	{
		path:"/news/newslist",
		component:NewsList
	},
	{
		path:"/product/productadd",
		component:ProductAdd
	},
	{
		path:"/product/productlist",
		component:ProductList
	},
	{
		path:"/trszcd/trszcdadd",
		component:TrszcdAdd
	},
	{
		path:"/trszcd/trszcdlist",
		component:TrszcdList
	},
	{
		path:"/zdlf/zdlfadd",
		component:ZdlfAdd
	},
	{
		path:"/zdlf/zdlflist",
		component:ZdlfList
	},
	{
		path:"/hmjjwx/hmjjwxadd",
		component:HmjjwxAdd
	},
	{
		path:"/hmjjwx/hmjjwxlist",
		component:HmjjwxList
	},
	{
		path:"/",
		redirect:"/index"
	},
	{
		path:"/:pathMatch(.*)*",
		name:"Notfound",
		component:NotFound
	}
]
export default routes