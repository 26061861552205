<template>
	 <el-row :gutter="20">
	    <el-col :span="12"><div>
		<el-card>
			<el-page-header content="首页广告" icon="" title="广告管理" />
			<!-- 添加图弹出框 -->
			<el-button @click="dialogVisible1 = true" type="primary" style="margin-top: 20px;"><el-icon><Plus /></el-icon>
					 </el-button>
					  <!-- 弹出框 -->
					 		   <el-dialog
					 		     v-model="dialogVisible1"
					 		     title="添加轮播图"
					 		     width="60%"
					 		   >
					 		    <el-form
					 		        ref="ggglFormRef1"
					 		        :model="ggglForm1"
					 		        :rules="ggglFormRules1"
					 		        label-width="80px"
					 		        class="demo-ruleForm"
					 		    >
					 		        <el-form-item
					 		            label="轮播图片"
					 		            prop="avatar"
					 		        >
					 		            <Upload
					 		                :avatar="ggglForm1.avatar"
					 		                @kerwinchange="handleChange1"
					 		            />
					 		        </el-form-item>
					 		    
					 		        <el-form-item>
					 		            <el-button
					 		                type="primary"
					 		                @click="submitForm()"
					 		            >添加产品</el-button>
					 		        </el-form-item>
					 		    </el-form>
					 		     <template #footer>
					 		       <span class="dialog-footer">
					 		         
					 		       </span>
					 		     </template>
					 		   </el-dialog>
			<!-- 滚动条 -->
			<el-scrollbar style="height:500px;">
				<el-table :data="tableData" style="width: 100%">
					<el-table-column type="index" label="序号" width="80" />
					<el-table-column label="图片" width="180">
						<template #default="scope">
							<div v-if="scope.row.avatar">
								<el-avatar shape="square" :size="50" style="width: 65px; height: 50px;"
									:src="proxy.$key+scope.row.avatar"></el-avatar>
							</div>
							<div v-else>
								<el-avatar :size="50" style="width: 65px; height: 50px;"
									src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
							</div>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template #default="scope">
							<el-button size="small" @click="handleEdit(scope.row)">编辑</el-button>
							<el-popconfirm title="你确定要删除吗?" confirmButtonText="确定" cancelButtonText="取消"
								@confirm="handleDelete(scope.row)">
								<template #reference>
									<el-button size="small" type="danger">删除</el-button>
								</template>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
			</el-scrollbar>
		</el-card>
		<!-- 弹出框 -->
		<el-dialog v-model="dialogVisible" title="编辑图片" width="50%">
			<el-form ref="ggglFormRef" :model="ggglForm" :rules="ggglFormRules" label-width="80px"
				class="demo-ruleForm">
				<el-form-item label="图片" prop="avatar">
					<Upload :avatar="ggglForm.avatar" @kerwinchange="handleChange" />
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="handleEditConfirm()">确认</el-button>
				</span>
			</template>
		</el-dialog>
	</div></el-col>
	    <el-col :span="12"><div>
		<el-card>
			<el-page-header content="产品广告" icon="" title="广告管理" />
			<!-- 添加图弹出框 -->
			<el-button @click="dialogVisible12 = true" type="primary" style="margin-top: 20px;"><el-icon><Plus /></el-icon>
					 </el-button>
					  <!-- 弹出框 -->
					 		   <el-dialog
					 		     v-model="dialogVisible12"
					 		     title="添加轮播图"
					 		     width="60%"
					 		   >
					 		    <el-form
					 		        ref="ggglptFormRef1"
					 		        :model="ggglptForm1"
					 		        :rules="ggglptFormRules1"
					 		        label-width="80px"
					 		        class="demo-ruleForm"
					 		    >
					 		        <el-form-item
					 		            label="轮播图片"
					 		            prop="avatar"
					 		        >
					 		            <Upload
					 		                :avatar="ggglptForm1.avatar"
					 		                @kerwinchange="handleChange22"
					 		            />
					 		        </el-form-item>
					 		    
					 		        <el-form-item>
					 		            <el-button
					 		                type="primary"
					 		                @click="submitForm2()"
					 		            >添加产品</el-button>
					 		        </el-form-item>
					 		    </el-form>
					 		     <template #footer>
					 		       <span class="dialog-footer">
					 		         
					 		       </span>
					 		     </template>
					 		   </el-dialog>
			<!-- 滚动条 -->
			<el-scrollbar style="height:500px;">
				<el-table :data="tableData2" style="width: 100%">
					<el-table-column type="index" label="序号" width="80" />
					<el-table-column label="图片" width="180">
						<template #default="scope">
							<div v-if="scope.row.avatar">
								<el-avatar shape="square" :size="50" style="width: 65px; height: 50px;"
									:src="proxy.$key+scope.row.avatar"></el-avatar>
							</div>
							<div v-else>
								<el-avatar :size="50" style="width: 65px; height: 50px;"
									src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
							</div>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template #default="scope">
							<el-button size="small" @click="handleEdit2(scope.row)">编辑</el-button>
							<el-popconfirm title="你确定要删除吗?" confirmButtonText="确定" cancelButtonText="取消"
								@confirm="handleDelete2(scope.row)">
								<template #reference>
									<el-button size="small" type="danger">删除</el-button>
								</template>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
			</el-scrollbar>
		</el-card>
		<!-- 弹出框 -->
		<el-dialog v-model="dialogVisible3" title="编辑图片" width="50%">
			<el-form ref="ggglptFormRef" :model="ggglptForm" :rules="ggglptFormRules" label-width="80px"
				class="demo-ruleForm">
				<el-form-item label="图片" prop="avatar">
					<Upload :avatar="ggglptForm.avatar" @kerwinchange="handleChange11" />
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible3 = false">取消</el-button>
					<el-button type="primary" @click="handleEditConfirm2()">确认</el-button>
				</span>
			</template>
		</el-dialog>
	</div></el-col>
	  </el-row>
	
</template>
<script setup>
	import {ref,onMounted,reactive} from "vue";
	import { Plus } from '@element-plus/icons-vue'
	import axios from "axios";
	import upload from "@/util/upload";
	import Upload from '@/components/upload/Upload'
	import {getCurrentInstance} from 'vue';
	const { proxy } = getCurrentInstance();
	const dialogVisible1 = ref(false)
	const dialogVisible = ref(false);
	const dialogVisible12 = ref(false)
	const dialogVisible3 = ref(false);
	const ggglptFormRef = ref();
	const ggglFormRef = ref();
	let ggglForm = reactive({
		avatar: "",
		file: null
	});
	const ggglFormRules = reactive({
		avatar: [{
			required: true,
			message: "请输图片",
			trigger: "blur"
		}],
	});
	
	const ggglFormRef1 = ref();
	let ggglForm1 = reactive({
		avatar: "",
		file: null
	});
	const ggglFormRules1 = reactive({
		avatar: [{
			required: true,
			message: "请输图片",
			trigger: "blur"
		}],
	});
	
	//每次选择完图片之后的回调
	const handleChange = file => {
		ggglForm.avatar = URL.createObjectURL(file);
		ggglForm.file = file;
	};
	//每次选择完图片之后的回调
	const handleChange1 = file => {
		ggglForm1.avatar = URL.createObjectURL(file);
		ggglForm1.file = file;
	};
	// 提交图片
	const submitForm = ()=>{
		    ggglFormRef1.value.validate(async (valid)=>{
		        if(valid){
		            //提交数据到后端
					console.log(ggglForm1);
					dialogVisible1.value = false
		            await upload("/adminapi/gggl/add",ggglForm1)
					getTableData();
		        }
		    })
		}

	const tableData = ref([]);
	onMounted(() => {
		getTableData();
	});
	// 获取全部数据
	const getTableData = async () => {
		// 获取列表数据
		const res = await axios.get("/adminapi/gggl/list",);
		console.log(res.data)
		tableData.value = res.data.data;
		
	};
	// 编辑回调
	const handleEdit = async (item) => {
		const res = await axios.get(`/adminapi/gggl/list/${item._id}`)
		Object.assign(ggglForm, res.data.data[0])
		dialogVisible.value = true;
	};
	//编辑确认回调
	const handleEditConfirm = () => {
		ggglFormRef.value.validate(async (valid) => {
			if (valid) {
				// console.log(ggglForm);
				// 1- 更新后端
				await upload("/adminapi/gggl/list", ggglForm)
				//2- dialog隐藏
				dialogVisible.value = false
				//3-获取table数据
				getTableData()
			}
		})
	}
	// 删除选中数据
	const handleDelete = async (item) => {
		const idavatar = reactive({
			_id: item._id, //id
			avatar: item.avatar, //图片名称
		})
		// console.log(item)
		await axios.delete("/adminapi/gggl/list",{
			params:idavatar
		})
		await getTableData()
	}
	
	
	
	
	// 产品广告
	let ggglptForm = reactive({
		avatar: "",
		file: null
	});
	const ggglptFormRules = reactive({
		avatar: [{
			required: true,
			message: "请输图片",
			trigger: "blur"
		}],
	});
	
	const ggglptFormRef1 = ref();
	let ggglptForm1 = reactive({
		avatar: "",
		file: null
	});
	const ggglptFormRules1 = reactive({
		avatar: [{
			required: true,
			message: "请输图片",
			trigger: "blur"
		}],
	});
	
	//每次选择完图片之后的回调
	const handleChange11 = file => {
		ggglptForm.avatar = URL.createObjectURL(file);
		ggglptForm.file = file;
	};
	//每次选择完图片之后的回调
	const handleChange22 = file => {
		ggglptForm1.avatar = URL.createObjectURL(file);
		ggglptForm1.file = file;
	};
	// 提交图片
	const submitForm2 = ()=>{
		    ggglptFormRef1.value.validate(async (valid)=>{
		        if(valid){
		            //提交数据到后端
					console.log(ggglptForm1);
					dialogVisible12.value = false
		            await upload("/adminapi/ggglpt/add",ggglptForm1)
					gettableData2();
		        }
		    })
		}
	
	const tableData2 = ref([]);
	onMounted(() => {
		gettableData2();
	});
	// 获取全部数据
	const gettableData2 = async () => {
		// 获取列表数据
		const res = await axios.get("/adminapi/ggglpt/list",);
		console.log(res.data)
		tableData2.value = res.data.data;
		
	};
	// 编辑回调
	const handleEdit2 = async (item) => {
		const res = await axios.get(`/adminapi/ggglpt/list/${item._id}`)
		Object.assign(ggglptForm, res.data.data[0])
		dialogVisible3.value = true;
	};
	//编辑确认回调
	const handleEditConfirm2 = () => {
		ggglptFormRef.value.validate(async (valid) => {
			if (valid) {
				// console.log(ggglptForm);
				// 1- 更新后端
				await upload("/adminapi/ggglpt/list",ggglptForm)
				//2- dialog隐藏
				dialogVisible3.value = false
				//3-获取table数据
				gettableData2()
			}
		})
	}
	// 删除选中数据
	const handleDelete2 = async (item) => {
		const idavatar = reactive({
			_id: item._id, //id
			avatar: item.avatar, //图片名称
		})
		// console.log(item)
		await axios.delete("/adminapi/ggglpt/list",{
			params:idavatar
		})
		await gettableData2()
	}
</script>
<style lang="scss" scoped>
	.pagin {
		margin: 10px 220px;
	}

	.el-table {
		margin-top: 50px;
	}

	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		transition: var(--el-transition-duration-fast);
	}

	::v-deep .avatar-uploader .el-upload:hover {
		border-color: var(--el-color-primary);
	}

	::v-deep .el-icon.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
	}
</style>