<template>
	<div>
		<el-card>
			<el-page-header content="产品列表" icon="" title="产品管理" />
			<!-- 滚动条 -->
			<el-scrollbar style="height:500px;">
				<el-table :data="tableData" style="width: 100%">
					<el-table-column prop="productname" label="名称" width="180" />
					<el-table-column prop="productnumber" label="编号" width="180" />
					<el-table-column label="图片" width="180">
						<template #default="scope">
							<div v-if="scope.row.avatar">
								<el-avatar shape="square" :size="50" style="width: 65px; height: 50px;"
									:src="proxy.$key+scope.row.avatar"></el-avatar>
							</div>
							<div v-else>
								<el-avatar :size="50" style="width: 65px; height: 50px;"
									src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
							</div>
						</template>
					</el-table-column>
					<el-table-column label="操作">
						<template #default="scope">
							<el-button size="small" @click="handleEdit(scope.row)">编辑</el-button>
							<el-popconfirm title="你确定要删除吗?" confirmButtonText="确定" cancelButtonText="取消"
								@confirm="handleDelete(scope.row)">
								<template #reference>
									<el-button size="small" type="danger">删除</el-button>
								</template>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
			</el-scrollbar>
		</el-card>
		<!-- 弹出框 -->
		<el-dialog v-model="dialogVisible" title="编辑新闻" width="50%">
			<el-form ref="productFormRef" :model="productForm" :rules="productFormRules" label-width="80px"
				class="demo-ruleForm">
				<el-form-item label="名称" prop="productname">
					<el-input v-model="productForm.productname" />
				</el-form-item>
				<el-form-item label="编号" prop="productnumber">
					<el-input v-model="productForm.productnumber" />
				</el-form-item>
				<el-form-item label="头像" prop="avatar">
					<Upload :avatar="productForm.avatar" @kerwinchange="handleChange" />
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="dialogVisible = false">取消</el-button>
					<el-button type="primary" @click="handleEditConfirm()">确认</el-button>
				</span>
			</template>
		</el-dialog>
		<div class="pagin">
			<el-pagination background layout="prev, pager, next,total" :total="data.total" :page-size="data.perPage"
				:current-page="data.page" @update:current-page="data.page = $event" @current-change="getTableData" />
		</div>
	</div>
</template>
<script setup>
	import {ref,onMounted,reactive} from "vue";
	import axios from "axios";
	import upload from "@/util/upload";
	import Upload from '@/components/upload/Upload'
	import {getCurrentInstance} from 'vue';
	const { proxy } = getCurrentInstance();
	// data响应数据
	const data = reactive({
		total: 0, //总条数
		page: 1, //第几页
		perPage: 12, //每页显示条数

	})
	const dialogVisible = ref(false);
	const productFormRef = ref();
	let productForm = reactive({
		productname: "",
		productnumber: "",
		avatar: "",
		file: null
	});
	const productFormRules = reactive({
		productname: [{
			required: true,
			message: "请输入名字",
			trigger: "blur"
		}],
		// productnumber: [{
		// 	required: true,
		// 	message: "请输入编号",
		// 	trigger: "blur"
		// }],
		avatar: [{
			required: true,
			message: "请输图片",
			trigger: "blur"
		}],
	});
	const tableData = ref([]);
	onMounted(() => {
		getTableData();
	});
	// 获取全部数据
	const getTableData = async () => {
		// 获取列表数据
		const res = await axios.get("/adminapi/product/list", {
			params: data
		});
		console.log(res.data)
		tableData.value = res.data.data;
		// 获取总页数
		data.total = res.data.total
	};
	// 编辑回调
	const handleEdit = async (item) => {
		const res = await axios.get(`/adminapi/product/list/${item._id}`)
		Object.assign(productForm, res.data.data[0])
		dialogVisible.value = true;
	};
	//每次选择完图片之后的回调
	const handleChange = file => {
		productForm.avatar = URL.createObjectURL(file);
		productForm.file = file;
	};
	//编辑确认回调
	const handleEditConfirm = () => {
		productFormRef.value.validate(async (valid) => {
			if (valid) {
				// console.log(productForm);
				// 1- 更新后端
				await upload("/adminapi/product/list", productForm)
				//2- dialog隐藏
				dialogVisible.value = false
				//3-获取table数据
				getTableData()
			}
		})
	}
	// 删除选中数据
	const handleDelete = async (item) => {
		const idavatar = reactive({
			_id: item._id, //id
			avatar: item.avatar, //图片名称
		})
		// console.log(item)
		await axios.delete("/adminapi/product/list",{
			params:idavatar
		})
		await getTableData()
	}
</script>
<style lang="scss" scoped>
	.pagin {
		margin: 10px 220px;
	}

	.el-table {
		margin-top: 50px;
	}

	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		transition: var(--el-transition-duration-fast);
	}

	::v-deep .avatar-uploader .el-upload:hover {
		border-color: var(--el-color-primary);
	}

	::v-deep .el-icon.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
	}
</style>